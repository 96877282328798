import API from "../../Network/API";
import { isLoading, isLoaded,  allProjects } from "../action";
import Alert from '../../Alert';


// get all project List
export async function getSquadsList(dispatch, empId) {
    console.log(empId)
    dispatch(isLoading())
    try {
        var response = await API.post("squads.php", {
            empId: empId,
            action: "get_squads"
        }, {}, false);
        if (response.status === 'True') {
            dispatch(allProjects(response.data))
        } else {
            dispatch(allProjects([]))
        }
    } catch (error) {
        Alert('error', error.message)
    }
    dispatch(isLoaded())
  
  }