import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function deleteSubTask(dispatch, getUser, subtaskId, mainTaskId, handleClose, handleModalClose, SUBTASK_DELETE) 
{
  console.log(subtaskId)
  dispatch(isLoading());
  try { //manageSubtasks.php
    var response = await API.post("manage_tasks.php", {
      "crop": getUser.corp,
      action: 'deletesubtask',
      subtaskId: subtaskId,
      maintaskId: mainTaskId,
      "projectId":getUser.projectId,
      empId: getUser.empId,
    }, {}, false);
    // console.log(response)
    if (response.status === 'true') {
      Alert("success", SUBTASK_DELETE)
      handleClose()
    } else if (response.status === "false") {
      handleModalClose()
      Alert("warning", response.message)
    }
  } catch (error) {
    Alert('error', error.message);
    handleClose()
  }
  dispatch(isLoaded());
}