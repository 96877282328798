import API from "../../Network/API";
import { isLoading, isLoaded,  employees } from "../action";
import Alert from '../../Alert';

// for get employee list in calendarDash
export async function getEmployeesDash(dispatch, getUser) {
  const domain = getUser.userName.split('@')[1]
    dispatch(isLoading());
    if(domain === 'gmail.com' || domain === 'yahoo.com' || domain === 'outlook.com'){
      try {
        var response = await API.post("squad_chat.php", {
          "crop": getUser.corp,
          projectId: getUser.projectId,
          action: 'get_all_employees_new',
          sendBy: getUser.empId
          // "empId": getUser.empId,
        }, {}, false);
        if (response.status === 'True') {
          var employee=[];
          response.data.map((x) => {
            return (x.email !== getUser.userName ? employee.push(x):null);
        })
          dispatch(employees(employee))
        }
        else {
          dispatch(employees([]))
        }
      } catch (error) {
        Alert('error', error.message);
      }
    }else{
    try {
      var responseData = await API.post("squad_chat.php", {
        "crop": getUser.corp,
        projectId: getUser.projectId,
        action: 'get_all_employees',
        domain: domain,
        sendBy: getUser.empId
        // "empId": getUser.empId,
      }, {}, false);
      console.log(response)
      if (responseData.status === 'True') {
        var employeeList=[];
        responseData.data.map((x) => {
          return (x.email !== getUser.userName ? employeeList.push(x):null);
      })
        dispatch(employees(employeeList))
      }
      else {
        dispatch(employees([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }}
    dispatch(isLoaded());
  }