import React from 'react';
import { Meet_URL } from '../config';
import jwt from 'jsonwebtoken';
import { useState, useEffect } from 'react';
import { removeSpecialCharacters } from '../commonUsage';


const JitsiComponent = (props) => {

  const [token, setToken] = useState(null);
  const [meetingUrl, setMeetingUrl] = useState(null)

  useEffect(() => {
    // Function to generate JWT token
    const generateToken = () => {
      // Replace this secret key with a secure key
      const secretKey = "RALLYWETASKER";

      // Sample payload data
      const payload = {
        "context": {
          "user": {
            "avatar": "",
            "name": "",
            "email": "",
          },
        },
        "aud": "jitsi",
        "iss": "23456789",
        "sub": Meet_URL,
        "room": removeSpecialCharacters(props.roomName),
        // "exp": 1700060038,
      };
      const generatedToken = jwt.sign(payload, secretKey);
      setToken(generatedToken);
      setMeetingUrl(`https://${Meet_URL}/${removeSpecialCharacters(props.roomName)}?jwt=${generatedToken}#jitsi_meet_external_api_id=98765432&config.startWithAudioMuted=true&config.startWithVideoMuted=true&config.autoCaptionOnRecord=true`)
    };

    generateToken();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {
        token !== null &&
        props.updateMeetingUrl(meetingUrl)
      }
    </>
  )
};

export default JitsiComponent;
