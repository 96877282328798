import { isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";

//For Modify MainTask
export async function updateMainTask(backlogs,activeStatus,currentDate,sprintTargetDate,state, dispatch, getUser, ideaId, moduleId, handleClose, MAINTASK_MODIFY, MODULE) {
    dispatch(isLoading());
    if (state.targetDate.value < currentDate && backlogs === '1') {
      dispatch(isLoaded());
      Alert('warning', 'Select valid date');
  
    }
    else if(state.targetDate.value  > sprintTargetDate && backlogs === '1'){
      dispatch(isLoaded());
      Alert('warning', `Target Date should not exceed ${MODULE} target date`);
    }
    // else if(state.id.value === null && state.userSelected === ''){
    //   dispatch(isLoaded());
    //   Alert('warning', `Select the Squad Member to assign ${MAINTASK}`);
    // }
    else if (state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "") {
      try {
        var response = await API.post("manage_userstories.php", {
          crop: getUser.corp,
          action: "modify",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          acceptanceCriteria: state.acceptanceCriteria.value,
          targetDate: state.targetDate.value === null ? currentDate : state.targetDate.value,
          module_id: state.moduleId.value,
          idea_id: state.epicSelected === "" ? state.ideaId.value : state.epicSelected,
          added_to: state.userSelected === "" ? state.id.value : state.userSelected,
          added_by: getUser.empId,
          mainTaskId: state.taskId.value,
          storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
          "projectId":getUser.projectId,
          assignedTo:state.id.value,
          change_assign:state.userSelected,
          activeStatus:activeStatus,
          device_id: state.device_id,
          player_id: state.player_id,
  
        }, {}, false);
        if (response.status === 'True') {
          // dispatch(getModuleMainTasks(dispatch, getUser, moduleId))
          Alert("success", MAINTASK_MODIFY);
        }
        else{
          Alert("warning", response.message);
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
    } else {
      Alert("warning", "please give all the details")
    }
  }