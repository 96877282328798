import {  isLoaded, isLoading, manageSubTasks } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function getSubTasks(dispatch, getUser, taskId) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_tasks.php", {
      crop: getUser.corp,
      mainTaskId: taskId,
      action: "getsubtasks",
      "projectId":getUser.projectId
    }, {}, false);
    if (response.status === 'true') {
      dispatch(manageSubTasks(response.data))
    }
    else {
      dispatch(manageSubTasks([]))
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
  dispatch(isLoaded());
}