import { isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";

//for add template only for modifyTemplate.js
export async function updateTemplateOnly(state, dispatch, getUser,handleClose, storyTitle, MAINTASK_ADD) {
    dispatch(isLoading());
    if (storyTitle === state.taskTitle.value){
      Alert("warning", "Please change user story title ")
      dispatch(isLoaded());
    }else if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
      try {
        var response = await API.post("manage_template.php", {
          crop: getUser.corp,
          action: "templateOnly",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          added_to: state.userSelected,
          added_by: getUser.empId,
          acceptanceCriteria: state.acceptanceCriteria.value,
          storyPoints: state.storySelected === "" ? '0' : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
          idea_id: state.epicSelected,
          "projectId":getUser.projectId,
          device_id: state.device_id,
          player_id: state.player_id,
        }, {}, false);
        if (response.status === 'True') {
          Alert("success", "Template added successfully ");
          handleClose();
        }
        else if(response.status === 'false' && response.message ==="TemplateExisted"){
          dispatch(isLoaded());
          Alert("warning", "Template already existed!, Please change user template title");
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
    }
   
    else {
      Alert("warning", "Please enter required fields ")
      dispatch(isLoaded());
    }
  }