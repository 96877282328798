import {
    isLoaded,
    isLoading,
    roadblock,
    roadblockFilter,
  } from "../actions";
import API from "../../../Common/Network/API";
import store from "../../../Common/Store";
  
//For RoadBlock List
export async function getRoadBlockUserStories(dispatch, getUser, moduleId, refresh) {
    if (refresh) { dispatch(isLoading()); }
    try {
      var response = await API.post(
        "get_user_stories.php",
        {
          crop: getUser.corp,
          action: "roadBlock",
          moduleId: moduleId,
          projectId: getUser.projectId,
          userType: getUser.role,
          empId: getUser.empId
        },
        {},
        false
      );
      if (response.message === "No Data Available") {
        store.dispatch(roadblock([]));
        store.dispatch(roadblockFilter([]));
      } else {
        if (!response.data) {
          store.dispatch(roadblock([]));
          store.dispatch(roadblockFilter([]));
        } else {
          store.dispatch(roadblock(response.data));
          store.dispatch(roadblockFilter(response.data));
        }
      }
    } catch (error) {
    }
  
    dispatch(isLoaded());
  }