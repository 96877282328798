import {
    isLoaded,
    isLoading,
  } from "../actions";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "./addCommentUserStory";
import { getCurrentSprintUserStories } from "./getCurrentSprintUserStories";
import Alert from "../../../Common/Alert";

//Update the Task Status
export async function updateUserStory(
    dispatch,
    getUser,
    userStoryId,
    sprintId,
    handleClose, message
  ) {
    dispatch(isLoading());
    try {
      const response = await API.post(
        "manage_userstories.php",
        {
          action: "update",
          task_id: userStoryId,
          crop: getUser.corp,
          task_status: 100,
          task_status_desc: "completed",
          task_complete_status: 1,
          empId: getUser.empId,
        },
        {},
        false
      );
      if (response.status === "True") {
        getCurrentSprintUserStories(dispatch, getUser, sprintId);
        addCommentUserStory(dispatch, getUser, userStoryId, message, "2");
      } else {
        Alert("warning", response.message);
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
    handleClose();
  }
  