import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "../../Modules/Services/addCommentUserStory";

export async function removeFromSprint(taskId, sprintId, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK) {
  const message = `This ${MAINTASK} removed from sprint ${sprintDesc}`

  dispatch(isLoading());

  try {
    var response = await API.post("getUpdateSprint.php", {
      crop: getUser.corp,
      action: "remove_from_sprint",
      sprintId: sprintId,
      userStoryId: taskId,
      "projectId": getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      addCommentUserStory(dispatch, getUser, taskId, message, "11");
      Alert("success", "UPDATED");
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
  handleClose()
}