import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { getSubTasks } from "./getSubtask";

export async function addSubTask(state, dispatch, getUser, mainTaskId, moduleId,assignedTo,targetDate, handleClose,SUBTASK_ADD, MODULE, MAINTASK, SUBTASK) {
  dispatch(isLoading());
//   if (state.targetDate.value  > targetDate && targetDate !== '0001-01-01') {
//     Alert("warning", `Target date should not exceed ${MAINTASK} target date`)
//     dispatch(isLoaded());
//   }
//  else if (state.targetDate.value < Moment(Date.now()).format("YYYY-MM-DD")  && targetDate !== '0001-01-01') {
//     dispatch(isLoaded());
//     Alert('warning', 'Select valid date');

//   }
//   else 
  if (state.targetDate.value !== "" && state.taskTitle.value !== "" && state.taskDescription.value !== "" ) {
    // let EstHours = Number(state.days.value * 24) + Number(state.hours.value);
    try {
      var response = await API.post("manage_tasks.php", {
        assignedBy: getUser.empId,
        assignedTo:assignedTo,
        "projectId":getUser.projectId,
        maintaskId: mainTaskId,
        moduleId: moduleId,
        crop: getUser.corp,
        action: "add",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        targetDate:state.targetDate.value,
        
      }, {}, false);
      if (response.status === 'true') {
        dispatch(getSubTasks(dispatch, getUser, mainTaskId))
        Alert("success", SUBTASK_ADD);
      }
      else{
        Alert('warning', `${MAINTASK} should be assigned to ${MODULE} before adding ${SUBTASK}`);
      }
    } catch (error) {
      Alert('warning', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "please give task information")
  }
}