import React, { useState } from 'react';
import '../../../Authentication/LandingPage/style.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useWebProperties } from '../../webProperties';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function ReleaseNote() {
  const { APP_NAME, IMG_SRC } = useWebProperties();
  const webProperties = useSelector(state => state.landingReducer.webProperties)
  const properties = useSelector(state => state.landingReducer.properties)
  const [searchQuery, setSearchQuery] = useState('');
  const releaseNotes = [
    {
      id: 81,
      version: "4.1.6",
      date: "01-31-2024",
      highlights: [
        "Bug Fixes: Mobile responsive issues fixed.",
        "Bug Fixes: My Tasks Chat issue fixed.",
        "Major Fixes: Complete UserModule Code Organization and Refactoring with CRUD Operations"
      ]
    },
    {
      id: 80,
      version: "4.1.5",
      date: "01-24-2024",
      highlights: [
        "Feature: Mobile responsive added.",
        "Bug Fixes: Updated Wetasker call link with Task ID.",
        "Bug Fixes: Fixed Task ID displayed is wrong Task ID while remove task from Timeline.",
        "Bug Fixes: Fixed Module closed successfully message while closing the Module.",
        "Bug Fixes: Fixed Dashboard ToDo action labels and action fixes",
        "Bug Fixes: Hide Stakeholder User role permissions"
      ]
    },
    {
      id: 79,
      version: "4.1.4",
      date: "01-18-2024",
      highlights: [
        "Bug Fixes: In Backlogs and Modules, Change status of user story(from ToDo to Inprogress (and/or) Inprogress to Done) issue fixed",
      ]
    },
    {
      id: 78,
      version: "4.1.3",
      date: "01-11-2024",
      highlights: [
        "Bug Fixes: Modules screen crashing issue fixed.",
        "Bug Fixes: Roadblock screen navigate to timelines if user role is contributor & LAC",
      ]
    },
    {
      id: 77,
      version: "4.1.2",
      date: "01-09-2024",
      highlights: [
        "Bug Fixes: User can change the status when they are in module page.",
        "Bug Fixes: Past target dates are not allowed while creating module.",
        "Bug Fixes: Subtask id added in subtask at module.",
      ]
    },
    {
      id: 76,
      version: "4.1.1",
      date: "01-03-2024",
      highlights: [
        "Bug Fixes: Redux alert issue fixed",
        "Bug Fixes: Web Notifications Enabled to WeTasker",
        "Bug Fixes: Case-Sensitive Project Name Validation.",
        "Bug Fixes: Roadblock Chat is working from Epic(Module)",
      ]
    },
    {
      id: 75,
      version: "4.1.0",
      date: "12-27-2023",
      highlights: [
        "Bug Fixes: Resolved chat box alignment issues.",
        "Bug Fixes: Eliminated mandatory fields when adding a user story to the Kanban board.",
        "Bug Fixes: Resolved Roadblock chat box issues.",
        "Bug Fixes: When you add a task from the Kanban board, it will be assigned to the Scrum Master by default. If no person is selected for the task, it will still appear on the Kanban board",

      ]
    },
    {
      id: 74,
      version: "4.0.9",
      date: "12-21-2023",
      highlights: [
        "Bug Fixes: Resolved issues related to property for Backlog & Squad.",
        "Bug Fixes: Chat window Draggable & Resizable.",
      ]
    },
    {
      id: 73,
      version: "4.0.8",
      date: "12-15-2023",
      highlights: [
        "Bug Fixes: Resolved issues related to property file names and duplicate file names, consolidating them into fewer columns for modification.",
        "Bug Fixes: Archive of user stories was resolved and moved with in the Sprint file.",
      ]
    },
    {
      id: 72,
      version: "4.0.7",
      date: "12-14-2023",
      highlights: [
        "Bug Fixes: Sprint with pending and completed user stories cannot be deleted.",
        "Bug Fixes: Pending user stories will moves into a next Sprint/Backlogs when sprint deleted.",
        "Bug Fixes: Pending user stories in a currently ending Sprint automatically moves into a next default Sprint ( automatically created if there are no new sprints created).",
        "Bug Fixes: Verify and reassign User Stories access given only to PO and PM.",
        "Bug Fixes: Chat window enlarged and resolution fixed- ui fixes.",
      ]
    },
    {
      id: 71,
      version: "4.0.6",
      date: "12-08-2023",
      highlights: [
        "Feature: Creating a default sprint involves checking if a sprint is not already available. If there is no existing sprint, the process includes creating a new one and moving all pending stories into this newly created sprint.",
        "Bug Fixes: Preventing duplicate task names for Roadblock while creating roadblock.",
        "Bug Fixes: Preventing duplicate template names while created save new template and add&save template.",
      ]
    },
    {
      id: 70,
      version: "4.0.5",
      date: "12-05-2023",
      highlights: [
        "Feature: Managing Backlog Tasks on Dashboard Todo and navigates to backlog board.",
        "Bug Fixes: More actions added based on user role and along with status change( i.e todo to in-progress or in-progress to done or in-progress to backlog(to created roadblock)) to planed and unplanned boards.",
        "Bug Fixes: Modified dashboard top navigation To Do label into My Tasks, dashboard ToDo to My Tasker and Personal ToDo to My Personal Tasker.",
        "Bug Fixes: Search on Todo in the dashboard with card numbers(story Id/task Id).",
        "Bug Fixes: Template will saving without Story points and priority points.",
        "Bug Fixes: Templates saved with duplicate Template names also added.",
      ]
    },
    {
      id: 69,
      version: "4.0.4",
      date: "11-24-2023",
      highlights: [
        "Bug Fixes: Fixed chat messages bunching issue in tasks chat,user chat, group chat and roadblock chat."
      ]
    },
    {
      id: 68,
      version: "4.0.3",
      date: "11-22-2023",
      highlights: [
        "Feature: Enhanced Dashboard To-Do with additional actions along with status change( i.e todo to in-progress or in-progress to done) for active Sprint/ Kanban board",
        "Feature: Moving tasks from inprogress to todo from Kanban/ Sprint board.",
        "Bug Fixes: Label fix for 'not planned board' to 'unplanned board' on dashboard todo.",
        "Bug Fixes: Improved Chatbot functionality.",
        "Bug Fixes: Fixed the issue with Scroll bar on top nav for switching projects."
      ]
    },
    {
      id: 67,
      version: "4.0.2",
      date: "11-17-2023",
      highlights: [
        "Feature: Option to enter hrs for task time hrs while adding or modifying.",
        "Feature: Video Conference Added In Group Chat.",
        "Feature: Contributors are not authorized to add team members and update employee status within the project.        ",
        "Bug Fixes: Video Conference Meeting Name Issue Fixed.",
        "Bug Fixes: Removed loader when user clicks on task name for task detail."
      ]
    },
    {
      id: 66,
      version: "4.0.1",
      date: "11-15-2023",
      highlights: [
        "Feature: Video Conference Added In Task Chat & Individual Chat.",
        "Feature: Generating Meeting link with out moderator credentials.",
        "Bug Fixes: Responsive TopNavBar Fixed."
      ]
    },
    {
      id: 65,
      version: "4.0.0",
      date: "11-10-2023",
      highlights: [
        "Copy of 3.0.40",
      ]
    },
    {
      id: 64,
      version: "4.0.0",
      date: "11-10-2023",
      highlights: [
        "Feature: Project Report Added at Employee Reports.",
        "Feature: TaskChat feature is added to todo tasks under dashboard's to-do.",
      ]
    },
    {
      id: 63,
      version: "3.0.39",
      date: "11-10-2023",
      highlights: [
        "Feature: In dashboard Todo, User can easily navigate to there respective board like sprint, backlogs and modules.",
        "Feature: Sorting completed userstories to bottom in active sprint on backlog page.",
        "Feature: Automatic default sprint board for first 30 days when a new project is added.",
        "Feature: Added scrollbar for view task details in Backlog tab.",
        "Feature: Increased File Upload Size Limit.",
        "Feature: Added validations for support ticket.",
        "Bug Fixes: Task details are not showing for some project when we click on Task.",
        "Bug Fixes: Enhanced Chatbot Responses.",
      ]
    },
    {
      id: 62,
      version: "3.0.38",
      date: "11-06-2023",
      highlights: [
        "Bug Fixes: Sprint board is disappearing for some projects.",
        "Bug Fixes: Message delivery time correction."
      ]
    },
    {
      id: 61,
      version: "3.0.37",
      date: "11-02-2023",
      highlights: [
        "Feature: Dashboard Search For Project.",
        "Feature: Scroll bar at project shifting.",
        "Feature: Contributors Cannot Modify Support Board User Stories.",
        "Bug Fixes: Dashboard Active User stories string Changed to dynamically",
        "Bug Fixes: Background color Topnav web site",
        "Bug Fixes: Fixed background color in modules for the 'complete status' label",
        "Bug Fixes: User Profile Efficiency Report.",
        "Bug Fixes: Top Navigation Chat fix.",
        "Bug Fixes: Showing Task status, task completed date at Modules at task info.",
        "Bug Fixes: Removed 'Add to Support Board' Option for Support Board Tickets.",
        "Bug Fixes: To Do on Top Navigation shows all your individual tasks and displays task's details upon clicking."
      ]
    },
    {
      id: 60,
      version: "3.0.36",
      date: "10-30-2023",
      highlights: [
        "Feature: Kanban board auto-created during project setup.",
        "Feature: Verify tasks from the Archive.",
        "Feature: individual Employee Efficiency available in user profile.",
        "Feature: Contributors can not modify userstories once it's moved into Sprint.",
        "Bug Fixes: Removed popup for chat messages.        ",
        "Bug Fixes: Updated Sprint Velocity Query on the Reporting Server.",
      ]
    },
    {
      id: 59,
      version: "3.0.35",
      date: "10-20-2023",
      highlights: [
        "Feature: Verified by information will display on hovering on verify from any module.",
        "Bug Fixes: Chatbot on landing page is responding with app specific details.",
        "Bug Fixes: Hide and unhide for Side navigation is fixed.",
        "Bug Fixes: Kanban Roadblock ticktets will be in Kanban.",
        "Bug Fixes: Add/ delete/ modidify Sqaud members.",
        "Bug Fixes: Blank page while Modifying Sprint got fixed.",
        "Bug Fixes: Email content for new users invitation and registration changed with the default password.",
      ]
    },
    {
      id: 58,
      version: "3.0.34",
      date: "10-18-2023",
      highlights: [
        "Feature: Userstories will be sorted according to ids on both the boards and Backlogs.",
        "Feature: Attach the image while contacting support.",
        "Feature: Task chat notification email will be sent to Assigned To, Assigned By, Scrum Master, Product Owner.",
        "Feature: Tick mark on Active Sprint/ Kanban board for verified UserStories.",
        "Feature: Filter the userstories of particular user by clicking on initials on Backlog.",
        "Feature: Restricted  access to a Contributor to modify a UserStory.",
        "Bug Fixes: Search functionality in Backlog.",
        "Bug Fixes: Modify and assign Roadblock ticket.",
        "Bug Fixes: add/modify/delete squad member.",
      ]
    },
    {
      id: 57,
      version: "3.0.33",
      date: "10-13-2023",
      highlights: [
        "Feature: Image from Support to go to the message of the userstory in the Kanban board.",
        "Bug Fixes: User initial Fixes at backlogs.",
        "Bug Fixes: Bug fix of the overdue Date of user stories.",
        "Bug Fixes: Verify  completed userstory from epic.",
        "Bug Fixes: Roadblock from Kanban will remain on Kanban.",
      ]
    },
    {
      id: 56,
      version: "3.0.32",
      date: "10-11-2023",
      highlights: [
        "Feature: Assigned By & Assigned To Initials of team member on all cards.",
        "Feature: Team member Initials & Task ID #  in Task Chat.",
        "Feature: Team member's initials on all places where their names are reflected.",
        "Feature: Contributors do not have access to Change the task assignment to a different team member.",
        "Feature: Modify Task of the Kanban board will have a target date.",
        "Feature: Verify Option for Completed tasks on Tasks.",
        "Bug Fixes: Search Bar in Task module.",
      ]
    },
    {
      id: 55,
      version: "3.0.31",
      date: "10-06-2023",
      highlights: [
        "Feature: Contributor does not have an access to move tasks from backlogs to current sprint.",
        "Feature: Kanban Status has a identifier.",
        "Feature: User Token Expired to 3 days auto log out.",
        "Bug Fixes: Project Report Fixes in admin panel.",
      ]
    },
    {
      id: 54,
      version: "3.0.30",
      date: "10-04-2023",
      highlights: [
        "Bug Fixes: Date Format Issue for Report changed to mm/dd/yyyy.",
        "Bug Fixes: Slider Images Issue Fixed on web properties.",
        "Bug Fixes: Time sheets defects (i.e;start time and end time).",
        "Bug Fixes: Full task information added in priority board cards details.",
        "Bug Fixes: undefined issue for all properties fixed including Landing page."
      ]
    },
    {
      id: 53,
      version: "3.0.29",
      date: "09-29-2023",
      highlights: [
        "Feature: The details of the tasks on Activity board and Kanban Board.",
        "Bug Fixes: Undefined issue side nav.",
        "Bug Fixes: calendar date selection issue.",
        "Bug Fixes: start time, end time fixes at employee reports.",
        "Bug Fixes: Topnav dashboard todo serial numbers based on user story.",
        "Bug Fixes: Fixed Calendar hovering issue on timesheets."
      ]
    },
    {
      id: 52,
      version: "3.0.28",
      date: "09-22-2023",
      highlights: [
        "Feature: Enhanced Employee Reports with Time Tracking",
        "Feature: Converted Task Comments into Table Format.",
        "Feature: Added Timezone Information to Dates.",
        "Bug Fixes: Message Formatting Improvements",
        "Bug Fixes: Support Ticket Assignment to 'Support Tickets EPIC'",
      ]
    },
    {
      id: 51,
      version: "3.0.27",
      date: "09-20-2023",
      highlights: [
        "Feature:  Admin Panel: Reports into tabular format and export option for All Tables.",
        "Feature: Task ID# added to Employee Reports.",
        "Bug Fixes: Fixed Google signup Warning issue.",
        "Bug Fixes: Limit Edit/Delete for restricted Team Squad users.",
        "Bug Fixes: Resolved Priority Board Time Sheet Issues.",
        "Bug Fixes: Enabled Adding Kanban Directly Without requiring a name for the board.",
        "Bug Fixes: Removed the repeat of Date in Active Sprint name Format( Dates shown on hovering)"
      ]
    },
    {
      id: 50,
      version: "3.0.26",
      date: "09-15-2023",
      highlights: [
        "Feature: Task card numbers are changed to Serial numbers.",
        "Bug Fixes: Warning Alert display when 'User already Registered' for signup",
        "Bug Fixes: In To Do, Days count display on Priority Board cards.",
        "Bug Fixes: Employee efficiency bug fixes, Reports are in table format to see the utilization details.",
        "Bug Fixes: Adding comments when creating user story in kanban board.",
        "Bug Fixes: Made label name dynamic  when adding user story to priority board from backlogs."
      ]
    },
    {
      id: 49,
      version: "3.0.25",
      date: "09-13-2023",
      highlights: [
        "Archive: Archive user story displays details of members and story details.",
        "Archive: Blank page of Archive resolved.",
        "Archive: Messages of Archive displays the details.",
        "Reports: Individual workload display issue resolved.",
        "Reports: Employee Reports Added - Utilization Based On Hours, Utilization Based on story Points.",
        "Chat Room: Group creation done the first time a project is created.",
        "Feature: Priority Board Cards display the Priority of Task.",
      ]
    },
    {
      id: 48,
      version: "3.0.24",
      date: "09-06-2023",
      highlights: [
        "Admin can access to make necessary edits to the landing page, such as changing font content, font color, and updating images.",
        "Display the pending count along with individual stories and their respective project names.",
        "Added Search Functionality For Release Notes & FAQs."
      ]
    },
    {
      id: 47,
      version: "3.0.23",
      date: "08-30-2023",
      highlights: [
        "The background color can be changed from the Admin Panel's properties.",
        "User efficiency reports have been updated."
      ]
    },
    {
      id: 46,
      version: "3.0.22",
      date: "08-23-2023",
      highlights: [
        "Bug Fixes on All Users (Including non-domain emails) can access the project.",
        "Bug Fixes on Numbers of hours across the Story points.",
        "Bug Fixes on Undefined Issue.",
        "Bug Fixes on Support and Report Bug Issue."
      ]
    },
    {
      id: 45,
      version: "3.0.21",
      date: "08-11-2023",
      highlights: [
        "Timesheets are updated based on individual sprints within the project.",
        "Generate CSV reports for Admin Reports, Agile Usage Reports, and Project Reports.",
        "A roadblock task can be created when moving a task into the 'Blocked' status. Users can reassign the task to the respective developer."
      ]
    },
    {
      id: 44,
      version: "3.0.20",
      date: "08-02-2023",
      highlights: [
        "Display task/userstory hours spent on backlogs, sprint and priority Board.",
        "User story - activity time.",
        "User should be assign when userstory moves to sprint when user not assigned.",
        "Project reports displayed individual working hours and active working users."
      ]
    },
    {
      id: 43,
      version: "3.0.19",
      date: "07-01-2023",
      highlights: [
        "Accounts creation/Edit from Super Admin.",
        "Display all the Accounts Details.",
        "TaskVelocity Reports.",
        "Subtask Archive added.",
        "Sprint Velocity Changed from BarGraph to Line Graph.",
        "TimeSheets Hours display for Individual stories on Sprint Board, Kanban Board and Backlogs.",
        "Bug fix for timesheets (kanban board story not shown in timesheets).",
        "UI Bug Fixes."
      ]
    },
    {
      id: 42,
      version: "3.0.18",
      date: "04-14-2023",
      highlights: [
        "Support Mail Sent for Reopen the Archive Project.",
        "Bug Fixes."
      ]
    },
    {
      id: 41,
      version: "3.0.17",
      date: "03-14-2023",
      highlights: [
        "Task24x7 Production Release was done with the labels.",
        "Agile24x7 production code change along with Task24x7 linked to the same GIT.",
        "Make Single Backend For Both task and Agile.",
        "Archive Projects has been added so that the owner of the project can archive the project.",
        "Priority Levels added in sprint board.",
        "AGroup details are displayed for Roadblocks chat.",
        "Calendar bugs are resolved."
      ]
    },
    {
      id: 40,
      version: "3.0.16",
      date: "02-02-2023",
      highlights: [
        "Kanban board has Archive and user story differentiation in backlog.",
        "Time zone for chats has been made location specific.",
        "Chat group for the entire project created when a project is created and group members are added to the project.",
        "Archive Project is an additional feature with the ability to reinstate the projects from the Admin panel."
      ]
    },
    {
      id: 39,
      version: "3.0.15",
      date: "01-06-2023",
      highlights: [
        "User stories in To Do shows what project it belongs to and is clickable to take to Active Scrum Board or backlog of that project where the story belongs.",
        "Epic shows the number of active user stories.",
        "Create/Modify the Epic with Target Date.",
        "Kanban Board added to the Side Nav and as a feature independent of Sprint.",
        "Contributor: Number of user stories taken vs completed shown.",
        "Bug Fixes."
      ]
    },
    {
      id: 38,
      version: "3.0.14",
      date: "12-09-2022",
      highlights: [
        "Hide/Un Hide Projects in dashboard - control from Individual user To Do on upper tab.",
        "In Dashboard - Projects display Active User Stories Count and if the project has an Active Sprint.",
        "Active Sprint and number of pending User stories shown at EPIC level.",
        "Remove user story from Sprint back to backlog.",
        "Bug Fixes."
      ]
    },
    {
      id: 37,
      version: "3.0.13",
      date: "11-28-2022",
      highlights: [
        "Active Sprint and number of pending User stories shown at EPIC level.",
        "Bug Fixes."
      ]
    },
    {
      id: 36,
      version: "3.0.12",
      date: "03-18-2022",
      highlights: [
        "Sprint Board or Scrum Board UI is more exposed."
      ]
    },
    {
      id: 35,
      version: "3.0.11",
      date: "03-11-2022",
      highlights: [
        "Calendar event notifications enabled for both web and mobile.",
        "Web notifications enabled for all chats.",
        "Messages display in sorted order in squad chat and chat room.",
        "Team member appreciation through Kudos points enabled for Agile squad."
      ]
    },
    {
      id: 34,
      version: "3.0.10",
      date: "02-25-2022",
      highlights: [
        "Profile shows the type of account (corporate, Free, Paid personal...).",
        "Chat messages show the last message display sorted according to name and time in chat room and personal chat window."
      ]
    },
    {
      id: 33,
      version: "3.0.9",
      date: "02-18-2022",
      highlights: [
        "Working status bar updated to available whenever a person logs into Agile or performs an activity on Active Sprint Board.",
        "Chat response with tag on a particular message in all chat.",
        "Admin level features:",
        "Chat response to a particular message available to Admin."
      ]
    },
    {
      id: 32,
      version: "3.0.8",
      date: "02-11-2022",
      highlights: [
        "Completing task in personal todo and also displaying the completed tasks in personal list.",
        "Working status bar updated along with - Done for the Day - Be right back - Out sick - On Vacation.",
        "Chat response with tag on a particular message in chat room."
      ]
    },
    {
      id: 31,
      version: "3.0.7",
      date: "02-04-2022",
      highlights: [
        "In To Do list only projects you are involved in user stories will be shown.",
        "Projects list in side navigation for header pages like To Do, Chat, Support etc.",
        "User Story template can be modified.",
        "Agile support added on every page, so that you can contact support without logging out.",
        "Agile Squad shows the working status of each user.",
        "Archive search functionality with user story details."
      ]
    },
    {
      id: 30,
      version: "3.0.6",
      date: "01-28-2022",
      highlights: [
        "Admin level features:",
        "Admin panel separation for each Domain and Main Account Holder.",
        "Display of squad list and group chat list sorted according to unread and time.",
        "User level features:",
        "Single sign-on enabled from Google.",
        "Calendar, ToDo, and Chat in every screen on the header part.",
        "Display of squad list and group chat list sorted according to unread and time."
      ]
    },
    {
      id: 29,
      version: "3.0.5",
      date: "01-21-2022",
      highlights: [
        "Admin level features:",
        "FAQ can be added from Admin Panel.",
        "Add, Modify and Delete the FAQ's.",
        "User level features:",
        "Contributor with Limited Access is restricted on all levels.",
        "User story - If a user has a particular user story In Progress then when you move another user story into in progress the system throws a warning to confirm and give details of USER STORY name that is already in In Progress.",
        "Default Target date displayed when user story is moved into Active Sprint from Backlog.",
        "Active Sprint Boards name Changed.",
        "Calendar has all details input from Dashboard."
      ]
    },
    {
      id: 28,
      version: "3.0.4",
      date: "01-13-2022",
      highlights: [
        "User level features:",
        "Personal ToDo List",
        "Modify and Delete the Task from Personal To Do",
        "Convert Personal To Do Task into User Story (Add Task to Agile Project)",
        "Contributor role with limited access added",
        "Calendar",
        "Calendar available on dashboard for all project details",
        "Display user stories and events specific to user",
        "Events can be added with or without selecting project name",
        "FAQ's available from the registration page"
      ]
    },
    {
      id: 27,
      version: "3.0.3",
      date: "01-07-2022",
      highlights: [
        "Admin Panel features:",
        "Usage reports on all verified and unverified users",
        "User registration date and last login date available",
        "User level features:",
        "Consolidated list of To Do from all Projects",
        "A private To Do list can be created by each user",
        "Assigning user to user story is not mandatory",
        "Group chat notifications in the chat room for both admin and user",
        "Under User Dashboard, Individual user chat notifications",
        "Payment Integration for paid accounts or upgrades",
        "Chat collaboration between specific domains improvised",
        "User story has provision to save a template"
      ]
    },
    {
      id: 26,
      version: "3.0.2",
      date: "12-10-2021",
      highlights: [
        "Admin Panel features:",
        "Efficiency of an employee shown in reports",
        "User Registration: Project Count and Project Names Displayed",
        "User level features:",
        "Chat message counts shown through Dashboard",
        "User able to chat with other employees from Different teams through common messaging on Dashboard",
        "By Default User story Target date being taken as end date of the sprint when it is being moved from Backlog to Active Sprint"
      ]
    },
    {
      id: 25,
      version: "3.0.1",
      date: "12-03-2021",
      highlights: [
        "Admin Panel features:",
        "Project reports added to see the number of active projects for each user",
        "Agile Usage report Added to see the number of users registered into the application and the status",
        "Chat message from admin added",
        "User level features:",
        "Chat message from admin to be displayed in the dashboard",
        "Contact page added to support",
        "Report bugs added to support"
      ]
    },
    {
      id: 24,
      version: "3.0.0",
      date: "11-18-2021",
      highlights: [
        "The Agile team is proud to announce the release of Task 24X7 3.0.0. We are very pleased to announce that we have PostgressSQL or Mysql as Database made available from this release",
        "Calendar notifications are redirected to your Calendar",
        "This point release contains many bug fixes and improvements"
      ]
    },
    {
      id: 23,
      version: "2.0.8",
      date: "11-12-2021",
      highlights: [
        "Admin Panel features:",
        "Group Chat added",
        "User level features:",
        "Dashboard added",
        "Delete and Exit from Group",
        "Email verification put as a reminder to the user and access given to the user for temporary use without email verification",
        "Access to the application given with a temporary password",
        "Support option given through email",
        "Each registered user given access to create 10 projects free of cost, and an upgrade option is given"
      ]
    },
    {
      id: 22,
      version: "2.0.7",
      date: "10-22-2021",
      highlights: [
        "Hover on every Icon on every screen",
        "Group Chat Available as Chat Room for Users",
        "Sprint committed vs uncommitted option given",
        "User registration email reminder sent again if the user has not activated the account",
        "UI alignments for Search and other add features"
      ]
    },
    {
      id: 21,
      version: "2.0.6",
      date: "10-14-2021",
      highlights: [
        "Admin Panel features:",
        "Number of user stories an individual is assigned to in each week added to reports in admin",
        "User level features:",
        "Comment box shows all the history of that particular user story",
        "User stories will be moved back to To Do at the end of the day. User will have to move their User story into In Progress once they start the work next day",
        "Chat - Edit images before sending is enabled",
        "Reports- Has Burn down chart added",
        "Sprint can be create and delete option given only by Scrum master or Product Owner"
      ]
    },
    {
      id: 20,
      version: "2.0.5",
      date: "10-07-2021",
      highlights: [
        "User level features:",
        "User - Comment and user story moving to In progress and any message windows displays the user story ID and name",
        "Roadblock- User story in Roadblock shows the ID and user story name",
        "Chat Message - included in archive"
      ]
    },
    {
      id: 19,
      version: "2.0.4",
      date: "10-01-2021",
      highlights: [
        "Admin Panel features:",
        "All Employee reports from Admin Panel (includes the time sheet-total hours)",
        "License key generation for the upgrade of an account",
        "Chat has the preview option of the screenshots put"
      ]
    },
    {
      id: 18,
      version: "2.0.3",
      date: "09-24-2021",
      highlights: [
        "Chat functionality changes",
        "Limit 5 project per user as a Scrum master/product Owner",
        "Auto refresh of Active Sprint screen"
      ]
    },
    {
      id: 17,
      version: "2.0.2",
      date: "09-17-2021",
      highlights: [
        "Admin Panel features:",
        "TimeSheets for every active employee available from Admin panel",
        "Reports are available based on the Days selected from Admin panel",
        "Activate or Deactivate user from Admin and Project level and unassignment of their user stories",
        "User level features:",
        "Calendar has add events and User story deadlines defined",
        "Email Notifications are sent any time there is a change in Calendar event or user story",
        "BurnUp chart added to reports",
        "Mobile Notifications are sent when there is an activating or assignment of the user story, user level and user story level chat"
      ]
    },
    {
      id: 16,
      version: "2.0.1",
      date: "08-13-2021",
      highlights: [
        "User level features:",
        "Activating single user story per user",
        "Calendar email notifications",
        "Profile: Working hours the user added",
        "Show active sprint's user story with different color in calendar",
        "Individual user story sort by user in backlog",
        "Each user's profile with their availability or shift duration"
      ]
    },
    {
      id: 15,
      version: "2.0.0",
      date: "08-07-2021",
      highlights: [
        "Single DB Migration"
      ]
    },
    {
      id: 14,
      version: "1.0.14",
      date: "07-02-2021",
      highlights: [
        "The user Stories are marked as incomplete and given as a not finished on target time",
        "A user story can be assigned smaller Tasks if user wants to subdivide the user stories",
        "Change Status has been modified to 1 click"
      ]
    },
    {
      id: 13,
      version: "1.0.13",
      date: "06-25-2021",
      highlights: [
        "Email notification on all chat modules",
        "Roadblocks can be assigned as a user story and in active sprint the story card will show the ID to the Roadblock User story",
        "Changing user story Status in the backlog manually"
      ]
    },
    {
      id: 12,
      version: "1.0.12",
      date: "06-11-2021",
      highlights: [
        "Mural Board",
        "Chat notifications and uploading file",
        "Add user story / Modify User story Browser compatibility issue -scrollable feature added in model",
        "Backlogs Task Information model size is increased and (1.Active/ de active squad member, 2. Inactive member not showing in squad)",
        "Epic: 1. Do not add empty epic while add/ modify the epic 2. Delete epic whenever epic doesn’t have user stories (whoever added the epic that person able to delete it)",
        "Bugs Fixed: 1. Delete option for Agile Squad member 2. Delete EPIC if it does not have user stories"
      ]
    },
    {
      id: 11,
      version: "1.0.11",
      date: "05-28-2021",
      highlights: [
        "In Chat files and images upload/download",
        "Loaders added while assign, modify and reassign user story",
        "Bugs Fixed"
      ]
    },
    {
      id: 10,
      version: "1.0.10",
      date: "05-21-2021",
      highlights: [
        "Support page with version release notes",
        "Sprint tab has removed from epics",
        "In squad members dropdown list has been updated with all squad members",
        "Bugs Fixed"
      ]
    },
    {
      id: 9,
      version: "1.0.9",
      date: "05-07-2021",
      highlights: [
        "Backlog board with Drag and Drop",
        "Reports for individual Workload",
        "Reassign the Completed User Stories from Backlogs and Epic Level",
        "Chat Bug solved in Active Sprint",
        "Bugs Solved"
      ]
    },
    {
      id: 1,
      version: "1.0.8",
      date: "04-27-2021",
      highlights: [
        "Modify Sprint in Backlogs",
        "Reports"
      ]
    },
    {
      id: 2,
      version: "1.0.7",
      date: "04-23-2020",
      highlights: [
        "Agile Secure version with CA.",
        "Epic completion without dependency of user story creation.",
        "Adding a key for epic and project.",
        "Sprint cycle time change to 11.59pm PST on the end date of Sprint.",
        "Date format changed in archive to mm/dd/yy.",
        "In blocked list, show only roadblocks of the current sprint (present sprint).",
        "In comment box, the last message of comment box to be shown first.",
        "Removed mobile validation in agile squad while add/modify squad member.",
        "Check if all the user stories actions are done by user in sprint board, sprint board cannot switch."
      ]
    },
    {
      id: 3,
      version: "1.0.6",
      date: "04-16-2021",
      highlights: [
        "No Epic Dependency.",
        "Search Bar added to backlog, and Archive.",
        "Alphabetic order for Agile project Names.",
        "Backlog part in two cases i.e. with epic and without epic.",
        "Roadblocks on current sprint board.",
        "In chatbox date format has changed.",
        "Activate and deactivate Squad member.",
        "Comment box changes.",
        "Date for Version has changed.",
        "Completion of epic.",
        "Add validations.",
        "Block list should be displayed on scrum board.",
        "Give an option to assign  while adding userstory."
      ]
    },
    {
      id: 4,
      version: "1.0.5",
      date: "04-09-2021",
      highlights: [
        "Forgot Password",
        "Landing page",
        "Registration page",
        "Backlog Board with active and feature sprints user stories and backlogs",
        "Reset Password",
        "New Squad member to be added can be picked from existing data",
        "Sprint card details",
        "Roles Displayed with Squad Name in the chatbox"
      ]
    },
    {
      id: 5,
      version: "1.0.4",
      date: "03-26-2021",
      highlights: [
        "Active sprint board",
        "Backlog Board",
        "Archive Reports",
        "Removed Dashboard",
        "Display Release Date for every version/release",
        "Sprint should have a display of Current Active Sprint user-stories"
      ]
    },
    {
      id: 6,
      version: "1.0.3",
      date: "03-12-2021",
      highlights: [
        "Switching between the projects from inside the application.",
        "Add project after login.",
        "Remove the corp code and user type selection while login.",
        "Email notification while registered or add squad and add new project."
      ]
    },
    {
      id: 7,
      version: "1.0.2",
      date: '',
      highlights: [
        "Removed admin role from application and added three roles(i.e. scrum master, contributor, product owner).",
        "User stories page and Backlogs page are merged into a single page as backlogs."
      ]
    },
    {
      id: 8,
      version: "1.0.1",
      date: '',
      highlights: [
        "PTMS core version with properties file modification for Agile"
      ]
    }
  ];
  const [filteredNotes, setFilteredNotes] = useState(releaseNotes);
  const handleSearchInputChange = e => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredResults = releaseNotes.filter(note =>
      note.highlights.some(highlight => highlight.toLowerCase().includes(query)) || note.date.includes(searchQuery) || note.version.includes(searchQuery)
    );

    setFilteredNotes(filteredResults);
  };
  return (
    <div>
      <nav className="navbar sticky-top navbar-expand-lg navbar-light" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
        <button style={{ background: 'white' }} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          {/* eslint-disable-next-line */}
          <a className="navbar-brand"><Link to={{ pathname: "/" }}>
            {/* <img src="images/common/agile2.png" width="170" alt="" /></Link> */}
            <img className="agile-supportlogo" src={properties?.IMG_SRC || IMG_SRC} width="170" alt="" /></Link>

          </a>
          <form className="form-inline my-2 my-lg-0 ml-auto" style={{ paddingTop: "16px" }}>
            {/* <!-- <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"> --> */}
            {/* eslint-disable-next-line */}
            <a className="btn my-2 my-sm-0 ml-auto" id="loginbtn" type="submit" ><Link style={{ color: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }} to={{ pathname: "/login" }}>Login</Link></a>
            {/* eslint-disable-next-line */}
            <a className="btn my-2 my-sm-0" id="signupbtn-support" type="submit" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}><Link style={{ color: 'white' }} to={{ pathname: "/" }}>Home </Link></a>
            {/* support page button */}
          </form>
        </div>
      </nav>

      <section id="section1">
        <div className="container">
          <div className="release-note mt-3">
            <div className="d-flex bd-highlight"> <div className="p-2 flex-grow-1 bd-highlight text-center"><h2>{webProperties.APP_NAME || APP_NAME} release notes</h2></div>  </div>
          </div>
        </div>
      </section>

      {/* collapse view */}
      <div className="container mt-2 ">
        <div className='d-flex justify-content-end'> <input className='form-control bt-n' type="search" placeholder="Search... highlights,date,version" value={searchQuery} onChange={handleSearchInputChange} style={{ width: '30%' }} /></div>
        <div className="accordion mt-3" id="accordionExample">
          {filteredNotes.length > 0 ? (
            filteredNotes.map(note => (
              <div className="card" key={note.id}>
                <div className="card-header" id={`heading${note.id}`}>
                  <h2 className="mb-0">
                    <button className="btn btn-link w-50 text-left" type="button" data-toggle="collapse" data-target={`#collapse${note.id}`} aria-expanded="true" aria-controls={`collapse${note.id}`} >
                      <h5 className="text-primary">{`${webProperties.APP_NAME || APP_NAME} version ${note.version} release notes`}</h5>
                    </button>
                    <button className="btn btn-link w-50 text-right" type="button" data-toggle="collapse" data-target={`#collapse${note.id}`} aria-expanded="true" aria-controls={`collapse${note.id}`}  >
                      <h5 className="text-primary">{`Date : ${note.date}`}</h5>
                    </button>
                  </h2>
                </div>
                <div id={`collapse${note.id}`} className="collapse" aria-labelledby={`heading${note.id}`} data-parent="#accordionExample" >
                  <div className="card-body">
                    <div className="releasenote-cardbody">
                      <div className="card-title text-capitalize mt-3 mb-3">
                        <h6>Highlights</h6>
                      </div>
                      <div className="list">
                        <ul>
                          {note.highlights.map((highlight, index) => (
                            <li key={index} className="ml-1">
                              {highlight}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No matching release notes found.</p>
          )}
        </div>
      </div>


      {/* End collapse view */}



      <footer id="footer-releasenote" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
        <div style={{ color: 'white' }}>
          {webProperties?.APP_NAME || APP_NAME} © Copyright {new Date().getFullYear()}. All Rights Reserved.
        </div>
      </footer>
    </div>
  )
}
