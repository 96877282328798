import {
    isLoaded,
    isLoading,
    todo,
    doing,
    todoFilter,
    doingFilter,
  } from "../actions";
import API from "../../../Common/Network/API";
import store from "../../../Common/Store";
  
//For ToDo and Doing Subtask List based on task active status
export async function getToDoUserStories(dispatch, getUser, moduleId, refresh) {
    // console.log(moduleId,getUser.corp)
    if (refresh) { dispatch(isLoading()); }
    try {
      var response = await API.post(
        "get_user_stories.php",
        {
          crop: getUser.corp,
          moduleId: moduleId,
          action: "pending",
          projectId: getUser.projectId,
          userType: getUser.role,
          empId: getUser.empId,
        },
        {},
        false
      );
      var toDoList = [];
      var doingList = [];
  
      response.data.map((pending) => {
        return pending.activeStatus === "0"
          ? toDoList.push(pending)
          : pending.activeStatus === "1"
            ? doingList.push(pending)
            : null;
      });
      store.dispatch(todo(toDoList));
      store.dispatch(doing(doingList));
      store.dispatch(todoFilter(toDoList));
      store.dispatch(doingFilter(doingList));
    } catch (error) {
      console.log(error);
    }
  
    dispatch(isLoaded());
  }
  