import React, { useState } from 'react';
import '../../../Authentication/LandingPage/style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Alert from '../../Alert';
import API from '../../Network/API';
import { isLoaded, isLoading } from '../../Actions/loading';
import RootLoader from '../../Loader/RootLoad'
import { Redirect } from 'react-router-dom';
// filepond
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
// import RootLoader from '../Loader/RootLoad';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import "../../vendor/doka.min.css";
// eslint-disable-next-line
import { create } from "../../vendor/doka.esm.min";
import { useWebProperties } from '../../webProperties';
// Register the plugins
registerPlugin(FilePondPluginImageEdit, FilePondPluginImagePreview, FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType, FilePondPluginImageResize, FilePondPluginImageTransform)

export default function ReleaseBug() {

  const dispatch = useDispatch();
  const loaderStatus = useSelector(state => state.loading.status)
  const webProperties = useSelector(state => state.landingReducer.webProperties)
  const properties = useSelector(state => state.landingReducer.properties)
  const [dataReport, setData] = useState({
    bug_one_sen: "",
    email_to_contact: "",
    steps_for_bug: ""
  })
  // eslint-disable-next-line
  const [redirect, setRedirect] = useState(false)

  const [docFile, setDocFile] = useState(null);
  const { APP_NAME, CHATAPI, IMG_SRC, SUPPORT_EMAIL } = useWebProperties();

  const [isCopied, setIsCopied] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const [comment, setComment] = useState("")

  const handleDoc = (file) => {
    const fileData = file.file
    // console.log("file",fileData)
    setDocFile(fileData)
  }

  const handleChange = (e) => {
    setErrorMessage(null)
    setData({
      ...dataReport,
      [e.target.name]: e.target.value
    })
  }
  // for sending bug report
  const fileHandle = async (story_id, emp_id) => {
    if (dataReport.email_to_contact !== undefined && dataReport.bug_one_sen !== undefined && dataReport.steps_for_bug !== undefined) {
      dispatch(isLoading());
      const data = new FormData();
      data.append('file', docFile);
      data.append('action', 'releaseBug');
      data.append('api', CHATAPI);
      data.append('bug_one_sen', dataReport.bug_one_sen);
      data.append('email_to_contact', dataReport.email_to_contact);
      // data.append('useful_links', dataReport.useful_links);
      data.append('steps_for_bug', dataReport.steps_for_bug);
      data.append('appName', APP_NAME);
      data.append('supportMail', SUPPORT_EMAIL);
      try {
        var response = await API.post("report_bug.php", data
          , {}, false);
        // console.log(response)
        if (response.status === "True") {
          sendChat(story_id, emp_id)
          setDocFile({})
        } else {
          Alert('warning', response.message)
        }
      } catch (error) {
        console.log(error)
      }
      dispatch(isLoaded());
    } else {
      Alert("warning", "Please fill required fields! ");
    }
  }


  const sendChat = async (story_id, emp_id) => {
    if (docFile === null) {
      var response = await API.post("user_story_chat.php", {
        action: "send",
        corp_code: "AgileNexApp",
        groupId: story_id,
        message: comment || `${dataReport.bug_one_sen}\n\n${dataReport.steps_for_bug}`,
        messagedBy: emp_id,
        msgId: " ",
        groupName: '',
        groupEmail: [],
        reply_id: "",
      }, {}, false);
      console.log(response)
    } else {
      dispatch(isLoading());
      const data = new FormData();
      data.append('action', 'media');
      data.append('api', CHATAPI);
      data.append('file', docFile);
      data.append('message', comment || `${dataReport.bug_one_sen}\n\n${dataReport.steps_for_bug}`)
      data.append('groupId', story_id)
      data.append('messagedBy', emp_id)
      data.append('corp_code', 'AgileNexApp');
      data.append('groupName', '');
      data.append('groupEmail', []);
      data.append('reply_id', "");

      var response1 = await API.post("user_story_chat1.php", data
        , {}, false);
      console.log(response1)
    }
  }

  const [errorMessage, setErrorMessage] = useState(null);

  const addMainTaskFromSupportToKanban = async () => {
    setErrorMessage(null)
    const { email_to_contact, bug_one_sen } = dataReport;

    if (!email_to_contact || !bug_one_sen) {
      // Alert("error", "Please provide at least one input.");
      setErrorMessage('Please fill all mandatory details. This will help us to address your issue promptly and effectively. Thank you.');
      return; // Do not proceed with the submission
    }
    try {
      var response = await API.post("manage_userstories.php", {
        action: "addMainTaskFromSupportToKanban",
        title: dataReport.bug_one_sen,
        description: dataReport.steps_for_bug,
        email: dataReport.email_to_contact,
        projectId: "b9594061fe6c271f0ce5cbcdc7ffb8e5",
        appName: APP_NAME,

      }, {}, false);
      if (response.status === 'True') {
        fileHandle(response.story_id, response.emp_id)
        setData({
          bug_one_sen: "",
          email_to_contact: "",
          steps_for_bug: ""
        })
        Alert("success", 'Thankyou for contacting us, we will reach out to you once the issue is fixed.');
      }
      else if (response.status === 'false') {
        console.log(response.message)
      }
    } catch (error) {
      Alert('error', error.message);
    }

  }
  if (redirect) {
    return <Redirect to="/" />
  }

  const myPaste = (e) => {
    window.addEventListener('paste', e => {
      console.log(e)

      if (e.clipboardData.files.length !== 0) {
        setDocFile(e.clipboardData.files[0])
        setIsCopied(true)
        setIsUploaded(false)
      }
    });
  }

  const handleChat = () => {
    setIsUploaded(!isUploaded)
    setIsCopied(false)
  }

  return (
    <div>
      <nav className="navbar sticky-top navbar-expand-lg navbar-light" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
        <button style={{background: 'white'}} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          {/* eslint-disable-next-line */}
          <a className="navbar-brand"><Link to={{ pathname: "/" }}>
            {/* <img src="images/common/agile2.png" width="170" alt="" /></Link> */}
            <img className="agile-supportlogo" src={properties?.IMG_SRC || IMG_SRC} width="170" alt="" /></Link>

          </a>
          <form className="form-inline my-2 my-lg-0 ml-auto" style={{ paddingTop: "16px" }}>
            {/* <!-- <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"> --> */}
            {/* eslint-disable-next-line */}
            <a className="btn my-2 my-sm-0 ml-auto" id="loginbtn" type="submit"><Link style={{ color: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }} to={{ pathname: "/login" }}>Login</Link></a>
            {/* eslint-disable-next-line */}
            <a style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }} className="btn my-2 my-sm-0" id="signupbtn-support" type="submit"><Link style={{ color: 'white' }} to={{ pathname: "/" }}>Home </Link></a>
            {/* support page button */}
          </form>
        </div>
      </nav>

      <section id="section1">
        <div className="container">
          <div className="release-note mt-5">
            <div className="d-flex bd-highlight"> <div className="p-2 flex-grow-1 bd-highlight"><h2>Report Bug</h2></div>  </div>
          </div>

        </div>
      </section>

      {/* collapse view */}
      <div className="container mt-5">
        <div className="accordion ctn" id="accordionExample">

          <div className="card">
            <div className="card-header" id="headingZero">
              <h2 className="mb-0" style={{ paddingLeft: "20px" }}>
                {/* eslint-disable-next-line */}
                <h5>Tell us about the bug you found! </h5>
              </h2>
            </div>

            {/*  */}
            {/* If Answer is NO, . */}
            <div id="collapseZero" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div className="card-body">
                <div className="releasenote-cardbody">
                  <div className="card-title text-capitalize mt-3 mb-3"><h6>When the bug is resolved, which email should we contact you at?<span style={{ color: "red" }} >*</span></h6></div>
                  <div class="form-group" style={{ height: 'auto' }}>
                    <input type="text" value={dataReport?.email_to_contact} onChange={e => handleChange(e)} class="form-control" id="title" name="email_to_contact" placeholder="Enter email" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-2px' }} />
                  </div>
                </div>
              </div>
            </div>

            <div id="collapseZero" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div className="card-body" style={{ paddingTop: "0px" }}>
                <div className="releasenote-cardbody">
                  <div className="card-title text-capitalize mt-3 mb-3"><h6> Could you please describe this bug in one sentence?<span style={{ color: "red" }} >*</span></h6></div>
                  <div class="form-group" style={{ height: 'auto' }}>
                    <input type="text" value={dataReport?.bug_one_sen} onChange={e => handleChange(e)} class="form-control" id="title" name="bug_one_sen"
                      placeholder="Scrum board or Active Sprint: Sprint Name not displaying " style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-2px' }} />
                  </div>
                </div>
              </div>
            </div>


            <div id="collapseZero" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div className="card-body" style={{ paddingTop: "0px" }}>
                <div className="releasenote-cardbody">
                  <div className="card-title text-capitalize mt-3 mb-3"><h6>Let us know how to reproduce the bug for us to work on?</h6></div>
                  <div class="form-group" style={{ height: 'auto' }}>
                    <textarea type="text" value={dataReport?.steps_for_bug} onChange={e => handleChange(e)} class="form-control" id="title" name="steps_for_bug"
                      placeholder="1. Go to Dashboards | 2. Add a new Project "
                      style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '23px', minHeight: "66px", height: "66px" }} />
                  </div>
                </div>
              </div>
            </div>

            <div id="collapseZero" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div className="card-body" style={{ paddingTop: "0px" }}>
                <div className="releasenote-cardbody">
                  <div className="card-title text-capitalize mt-3 mb-3"><h6>Let’s chat more about this issue</h6></div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <textarea className="form-control copyable-text selectable-text " id="recipient-name document_attachment_doc " style={{ borderColor: 'grey', borderRadius: '20px', height: '2.2rem', padding: "7px" }}
                      contentEditable="true" suppressContentEditableWarning={true} placeholder="Type a message" onChange={(event) => setComment(event.target.value)}
                      onPaste={(e) => myPaste(e)}></textarea>
                    <div className="col-md-1" type="button" onClick={() => handleChat()}>
                      <img src="images/common/paper-clip.png" alt="logo"
                        style={{ width: '32px', marginTop: "-5px" }} />
                    </div>
                  </div>
                  <div class="form-group" style={{ height: 'auto' }}>
                    <div>
                      {
                        isCopied &&

                        <FilePond
                          allowFileTypeValidation={true}
                          fileValidateTypeLabelExpectedTypes={['allTypes']}
                          allowMultiple={true}
                          maxFiles={1}
                          instantUpload={false}

                          files={[docFile]}
                          storeAsFile={true}
                          // eslint-disable-next-line
                          credits={"", ""}
                          // allowProcess = {false}
                          onaddfilestart={(file) => handleDoc(file)}

                          // start for image editor
                          allowReorder={true}
                          onpreparefile={(file, output) => {
                            // console.log("prepare file", file.getMetadata());
                            // console.log("prepare", output);
                            const img = document.createElement("img");
                            img.src = URL.createObjectURL(output);
                            // document.body.appendChild(img);
                          }}
                          imageResizeUpscale={false}
                          imageResizeMode={"contain"}
                          imageEditIconEdit='<svg width="26" height="26" viewBox="0 0 26 26" 
                                                                  xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8.5 17h1.586l7-7L15.5 8.414l-7 7V17zm-1.707-2.707l8-8a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-8 8A1 1 0 0 1 10.5 19h-3a1 1 0 0 1-1-1v-3a1 1 0 0 1 .293-.707z"
                                                                   fill="blue" fill-rule="nonzero" stroke="blue" stroke-width="2"></path></svg>'
                          imageEditEditor={create({
                            onconfirm: () => {
                              setTimeout(() => {
                                Alert("warning", "Please click on upload icon (⬆️) for sending edited image.")
                              }, 500);
                            },
                            cropMinImageWidth: 128,
                            cropMinImageHeight: 128
                          })}
                          // end for image editor
                          server={{
                            // fake server to simulate loading a 'local' server file and processing a file
                            process: (fieldName, files, metadata, load) => {
                              // simulates uploading a file
                              setTimeout(() => {
                                load(Date.now())
                                addMainTaskFromSupportToKanban()
                                // handleImageChange(file) 
                              }, 1000);
                            },
                            load: (files, load) => {
                              // simulates loading a file from the server
                              fetch(files).then(res => res.blob()).then(load);
                            }
                          }}
                        >
                        </FilePond>
                      }
                    </div>
                    <div>
                      {
                        isUploaded &&

                        <FilePond
                          allowFileTypeValidation={true}
                          fileValidateTypeLabelExpectedTypes={['allTypes']}
                          allowMultiple={true}
                          maxFiles={1}
                          instantUpload={false}
                          storeAsFile={true}
                          // eslint-disable-next-line
                          credits={"", ""}
                          // allowProcess = {false}
                          onaddfilestart={(file) => handleDoc(file)}

                          // start for image editor
                          allowReorder={true}
                          onpreparefile={(file, output) => {
                            // console.log("prepare file", file.getMetadata());
                            // console.log("prepare", output);
                            const img = document.createElement("img");
                            img.src = URL.createObjectURL(output);
                            // document.body.appendChild(img);
                          }}
                          imageResizeUpscale={false}
                          imageResizeMode={"contain"}
                          imageEditIconEdit='<svg width="26" height="26" viewBox="0 0 26 26" 
                                                                  xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8.5 17h1.586l7-7L15.5 8.414l-7 7V17zm-1.707-2.707l8-8a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-8 8A1 1 0 0 1 10.5 19h-3a1 1 0 0 1-1-1v-3a1 1 0 0 1 .293-.707z"
                                                                   fill="blue" fill-rule="nonzero" stroke="blue" stroke-width="2"></path></svg>'
                          imageEditEditor={create({
                            onconfirm: () => {
                              setTimeout(() => {
                                Alert("warning", "Please click on upload icon (⬆️) for sending edited image.")
                              }, 500);
                            },
                            cropMinImageWidth: 128,
                            cropMinImageHeight: 128
                          })}
                          // end for image editor
                          server={{
                            // fake server to simulate loading a 'local' server file and processing a file
                            process: (fieldName, files, metadata, load) => {
                              // simulates uploading a file
                              setTimeout(() => {
                                load(Date.now())
                                addMainTaskFromSupportToKanban()
                                // handleImageChange(file) 
                              }, 1000);
                            },
                            load: (files, load) => {
                              // simulates loading a file from the server
                              fetch(files).then(res => res.blob()).then(load);
                            }
                          }}
                        >
                        </FilePond>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {errorMessage != null && <div style={{ color: 'red', marginLeft: '100px', marginBottom: '30px', fontSize: '15px' }}>{errorMessage}</div>}

            <div className="col-sm-12 col-md-12">
              {(loaderStatus) ? <RootLoader /> :
                <button type="button" class="btn btn-success w-100" style={{ borderRadius: '20px', height: '2.2rem', fontSize: "19px", backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b', }}
                  onClick={() => addMainTaskFromSupportToKanban()}
                >Submit</button>}
            </div>

          </div>
          <br />


        </div>
      </div>


      {/* End collapse view */}



      <footer id="footer-releasenote" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
        <div style={{ color: 'white' }}>
          {webProperties?.APP_NAME || APP_NAME} © Copyright {new Date().getFullYear()}. All Rights Reserved.
        </div>
      </footer>
    </div>
  )
}
