import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "../../Modules/Services/addCommentUserStory";


//adding userstory to kanban
export async function addToKanban(currentDate, sprintTargetDate, targetDate, taskId, sprintId, state, dispatch, getUser, handleClose, device_id, assign_to, sprintDesc, title, player_id, storyPoints, MODULE, MAINTASK) {
  const message = `This ${MAINTASK} added in sprint ${(getUser.corp).substring(0, 3).toUpperCase()} - ${sprintDesc}`
  dispatch(isLoading());
  if (targetDate < currentDate && targetDate !== '') {
    Alert('warning', 'Select valid date');

  }
  else if (targetDate > sprintTargetDate && targetDate !== '') {
    Alert('warning', `Target Date should not exceed  ${MODULE} target date`);
  }
  else {

    try {
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        action: "sprint_update",
        sprintId: sprintId,
        userStoryId: taskId,
        targetDate: (targetDate === '') ? sprintTargetDate : targetDate,
        "projectId": getUser.projectId,
        device_id: device_id === null ? state.device_id : device_id,
        player_id: player_id === null ? state.player_id : player_id,
        sprintDesc: sprintDesc,
        assign_to: assign_to === null ? state.userSelected : assign_to,
        emp_id: getUser.empId,
        title: title,
        storyPoints: storyPoints === '0' ? state.storySelected : storyPoints,
      }, {}, false);
      if (response.status === 'True') {
        addCommentUserStory(dispatch, getUser, taskId, message, "11");

        Alert("success", "UPDATED");
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  }

}