import React, { useEffect, useState, useReducer } from 'react'
import API from '../../Common/Network/API';
import { useSelector } from "react-redux";
import TopNavWithOutProject from '../Utility/TopNav/topnav'
import { setStatus, setToken, setRoleCount, getRoleCount } from '../../Common/LocalStorage';
import { setCurrentUser } from '../../Common/Actions';
import jwt_decode from "jwt-decode";
import Alert from '../../Common/Alert';
import RootLoader from '../../Common/Loader/RootLoader';
// import { properties } from '../../AdminModule/Properties/actions';
import store from '../../Common/Store';

import { dashboardReducer, initialState } from "./dashboardReducer";
import { setPlayerId } from '../../Authentication/Login/network';
import OneSignal from 'react-onesignal';
import { useWebProperties } from '../../Common/webProperties';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Client_URL } from '../../Common/config';
import { getAllUserMessages } from './Services/getAllUserMessages';
import { getAllGroupMessages } from './Services/getAllGroupMessages';
import { getAllUserstoriesMessages } from './Services/getAllUserstoriesMessages';

export default function Dashboard() {
  const history = useHistory();
  const { ACTIVE_STORIES, MODULE, NEW_PROJECT, PRODUCT_OWNER, SCRUM_MASTER, MODULES, APP_NAME, role_array, KANBAN } = useWebProperties();
  const [userSquadList, UpdateUserSquadList] = useState([])
  const [projectSprintActive, UpdateProjectSprintActive] = useState([])

  const getUser = useSelector(state => state.auth)
  // const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(false);
  const [squadName, updateSquadName] = useState('')
  const [searchQuery, setSearchQuery] = useState('');
  // eslint-disable-next-line
  const [userSquad, UpdateUserSquad] = useState(getUser.user.corp);
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useReducer(dashboardReducer, initialState);
  const { role } = getUser.user;
  useEffect(() => {
    OneSignal.init({
      // appId: "abea56d5-c615-4e38-b9f3-c5f2da474622"//local
      appId: "01df9ab9-9ed3-4e43-9eed-09795eb45570f"//production
    });
    OneSignal.getUserId().then(function (userId) {
      // console.log("OneSignal User ID:", userId);
      setToken('player_id', userId) //store the token information  with exp
      setPlayerId(getUser.user)
    });
    // eslint-disable-next-line
  }, [])
  // const [userSquad, UpdateUserSquad] = useState(getUser.user.corp);//Show the Squad Name

  const SquadChangeStatus = async (projectInfo) => {
    try {
      const response = await API.post("squad_login.php", {
        username: getUser.user.empId,
        project_id: projectInfo.id,
        corp: projectInfo.value,
      }, {}, false);
      if (response.status === 'TRUE') {
        setToken('auth', response.jwt) //store the token information  with exp
        const tokenDetails = jwt_decode(response.jwt);
        // console.log(tokenDetails.data)
        setRoleCount('roleCount', tokenDetails.data.roleCount)
        store.dispatch(setCurrentUser(tokenDetails.data)); //store the user information
        setStatus('status', tokenDetails.data.empStatus)
        setRedirect(true)
        UpdateUserSquad(squadName)
      } else {
        UpdateUserSquad(getUser.user.corp)
      }
    } catch (error) {
      // Alert('error',error.message)
      UpdateUserSquad(getUser.user.corp)
    }
    // dispatch(isLoaded());
  }

  const getSquadsList = async (empId) => {
    setLoading(true)
    try {
      var response = await API.post("squads.php", {
        empId: empId,
        action: "get_squads"
      }, {}, false);
      if (response.status === 'True') {
        let projects_list = [];
        response.data.map(projects => {
          return response.stories_count.filter(stories => projects.id === stories.project_id).map(list => {
            return projects_list.push({ id: projects.id, color: projects.color, value: projects.value, is_enable: projects.is_enable, emp_id: projects.emp_id, stories_count: list.stories_count });
          })
        })
        UpdateProjectSprintActive(response.active_sprint)//Projects Active Sprints
        UpdateUserSquadList(projects_list)
      } else {
        UpdateUserSquadList([])
      }
    } catch (error) {
      // Alert('error', error.message)
    }
    setLoading(false)

  }
  const onValidate = () => {
    var validationRule = /^\S{3,}$/;
    if (squadName === "") {
      Alert('warning', "please give the project name")
    } else if (!validationRule.test(squadName)) {
      Alert('warning', " please do not use spaces, '.' and '-' in between words")
    } else if (squadName.length < 4 || squadName.length > 16) {
      Alert('warning', " project name should having length 4 to 15 characters")
    } else {
      return true
    }
  }

  const onProjectCreate = async () => {
    var user = 'admin@'
    var [, extension] = getUser.user.userName.split('@')
    setLoading(true)
    if (onValidate()) { //Validate the project name
      try {
        const response = await API.post("squads.php", {
          adminUsername: user.concat(extension),
          extension: extension,
          username: getUser.user.userName,
          squadName: squadName,
          action: "new_squad",
          empId: getUser.user.empId,
          appName: APP_NAME,
          url: Client_URL,
        }, {
        });
        if (response.status === "True") {
          //add kanban
          addKanban(getUser.user.empId, KANBAN, response.projectId)
          getSquadsList(getUser.user.empId);
          Alert('success', response.message)

        } else {
          Alert('warning', response.message)
        }
      }
      catch (error) {
        // Alert('error', error.message)
      }
    } else {
      // Alert('warning', "please give the project name")
    }
    setOpen(false)
    setLoading(false)

  }

  //Add new Kanban

  function addKanban(getUser, KANBAN, projectId) {
    try {
      const data = {
        module_Name: KANBAN,
        empId: getUser, //Async
        action: "add",
        targetDate: '0001-01-01',
        startDate: '0001-01-01',
        projectId: projectId
      };
      const response = API.post("manage_sprints.php", data, {}, false);
      if (response.status === 'True') {
        //  dispatch(getModules(dispatch,getUser))
        // Alert("success", `${KANBAN} added`);
      }
      else {
        // alert("something went wrong")
      }
    } catch (error) {
      // Alert("error", error.message);
    }
  }
  useEffect(() => {
    getAllUserstoriesMessages(setInfo, getUser.user)
    getAllUserMessages(setInfo, getUser.user)
    getAllGroupMessages(setInfo, getUser.user)
    getSquadsList(getUser.user.empId); // GNK --> 01
    // store.dispatch(properties(getToken('properties')))
    // eslint-disable-next-line
  }, [])
  if (redirect) {
    console.log(getRoleCount('roleCount'), MODULES);
    if ((getRoleCount('roleCount') >= 1)) {
      // return <Redirect to={`/${MODULES}`} />
      history.push(`/${MODULES}`);
    } else {
      history.push(`/`);
      // return <Redirect to="/" />
    }
  }
  //Project having Active Sprint 
  const projectActiveSprint = (projectSprintActive, project_id) => {
    if (MODULE === undefined) {
      window.location.reload()
    }
    const activesprint = projectSprintActive.filter(activeSprints => activeSprints.project_id === project_id).map(({ sprint_active }) => sprint_active);
    return (activesprint.length > 0 ? `Active ${MODULE}` : `No Active ${MODULE}`);
  }

  const filteredSquads = userSquadList.filter(projects => projects.is_enable === '1').filter(projects => {
    return projects.value.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  }

  const getMessagesCount = (p_id, msg, empId, userMsg, groupMsg) => {
    const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1
      && (message.messagedBy !== empId && message.project_id === p_id)).map((messages, i) => {
        // eslint-disable-next-line
        return i, messages
      })
    const msgCountUser = userMsg.filter(message => message.project_id === p_id).map((messagess, index) => {
      // eslint-disable-next-line
      return index, messagess
    })
    const msgCountGroup = groupMsg.filter(message => message.readBy.split(",").indexOf(empId) === -1
      && message.messagedBy !== empId && message.project_id === p_id).map((messagesGroup, id) => {
        // eslint-disable-next-line
        return id, messagesGroup
      })
    return (
      <i>
        {msgCount.length + msgCountUser.length + msgCountGroup.length > 0 ? (
          <i style={{ background: "whitesmoke" }}>
            <span style={{ marginLeft: '70%' }}>Messages</span>
            <span title="Unread Messages" style={{ color: "red", fontWeight: "bold", background: "whitesmoke" }}>
              {msgCount.length + msgCountUser.length + msgCountGroup.length}
            </span>
          </i>
        ) :
          <i style={{ background: "whitesmoke" }}>
            <span style={{ color: "whitesmoke", fontWeight: "bold", marginLeft: "92%", background: "whitesmoke" }}>
              {msgCount.length}
            </span></i>}
      </i>
    );
  };
  // console.log("function",getMessagesCount('93c869c7c2cea585a1d85063a44b56de',info.allMessages, getUser.user.empId))

  return (
    <div>
      <TopNavWithOutProject />
      <div className="" style={{ marginTop: 80 }}>
        <div className="row" style={{ alignItems: 'baseline', justifyContent: 'flex-end' }}>
          <div className='col-lg-4 col-md-12 d-flex justify-content-center'>
            {open === false ? (
              <button
                className="btn btn-link text-decoration-none"
                type="button"
                onClick={() => setOpen(true)}
              >
                <img
                  src="images/common/add.png"
                  title="New Project"
                  alt="logo"
                  style={{ width: '20px', height: '20px' }}
                />
                <span className="m-1">Add {NEW_PROJECT}</span>
              </button>
            ) : (
              <div className="col-md-12 col-lg-12">
                <div className="mb-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Project Name"
                    onChange={(e) => updateSquadName(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    className="btn btn-success btn-block mt-1 mr-1"
                    onClick={() => onProjectCreate()}
                  >
                    Add
                  </button>
                  <button
                    className="btn btn-danger btn-block mt-1 ml-1"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-2 col-md-12 d-flex justify-content-md-end justify-content-center">
            <div className='mt-1' style={{ display: 'flex', alignItems: 'center' }}>
              <label className='mr-2 mb-0' htmlFor='searchInput'>Search:</label>
              <input type="text" className="form-control" style={{ height: '30px', width: '250px' }} aria-label="Search" aria-describedby="basic-addon2" value={searchQuery} onChange={handleSearchInputChange} />
            </div>
          </div>
        </div>
      </div>



      <div className="row justify-content-center" >


        {loading === true ? <RootLoader /> :
          // userSquadList.filter(projects => projects.is_enable === '1').map(projects => {
          //   return (
          filteredSquads.map(projects => {
            return (
              <div key={projects.id}>
                {/* { getMessagesCount(projects.id,info.allMessages, getUser.user.empId) } */}
                <div className="shadow-lg bg-white rounded m-5" style={{ width: 250 }}>
                  {getMessagesCount(projects.id, info.allMessages, getUser.user.empId, info.allMessagesUser, info.allGroupMessages)}
                  <div className="dcenter" style={{ backgroundColor: projects.color, display: 'flex', flexDirection: 'column', fontWeight: 600, alignItems: 'center' }}>
                    <p onClick={() => SquadChangeStatus(projects)}
                      // corp === projects.value ? 'floralwhite' :  fontWeight: corp === projects.value && 'bold'
                      style={{ color: '#2f4f4f', marginTop: 45, cursor: 'pointer', fontSize: '20px' }}>
                      {projects.value}
                      {/* { getMessagesCount(projects.id,info.allMessages, getUser.user.empId) }  */}
                    </p>
                    {(role_array[role] === SCRUM_MASTER || role_array[role] === PRODUCT_OWNER) && <p style={{ fontWeight: '600', color: '#2f4f4f' }}>{ACTIVE_STORIES}: {projects.stories_count}</p>}
                    {<p style={{ fontWeight: '600', color: '#2f4f4f' }}>{projectActiveSprint(projectSprintActive, projects.id)}</p>}
                    {/* { getMessagesCount(projects.id,info.allMessages, getUser.user.empId) } */}
                  </div>

                </div>
              </div>


            )
          })
        }
      </div>
    </div>
  )
}
