import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


export async function modifySubTask(state, dispatch, getUser, mainTaskId, handleClose, SUBTASK_MODIFY) {
  dispatch(isLoading());
  if (state.taskTitle.value !== "" && state.taskDescription.value !== "") {
    try {
      var response = await API.post("manage_tasks.php", {
        assignedBy: getUser.empId,
        subtaskId: state.subTaskId.value,
        "projectId":getUser.projectId,
        crop: getUser.corp,
        action: "modify",
        maintaskId: mainTaskId,
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        empId: getUser.empId,
      }, {}, false);
      if (response.status === 'true') {
        // dispatch(getSubTasks(dispatch, getUser, mainTaskId))
        Alert("success", SUBTASK_MODIFY);
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "please give task title and description")
  }
}