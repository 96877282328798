import { isLoading, isLoaded } from "../../../Common/Actions/loading"
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { getToDo } from "./getToDo";
import { getDone} from "./getDone";
import { getTasksCount} from  "./getTasksCount"
 //Update the Task Status
 export async function updateUserStory(dispatch,getUser,userStoryId, MAINTASK) {
    dispatch(isLoading());
       try {
           const response = await API.post("manage_userstories.php", {
               action: "update",
               task_id: userStoryId,
               crop: getUser.corp,
               task_status: 100,
               task_status_desc: "completed",
               task_complete_status: 1,
               empId: getUser.empId,
           }, {}, false);
           if (response.status === "True") {
               getToDo(dispatch,getUser)//For ToDo and Doing Tasks
               getDone(dispatch,getUser)//For Done Tasks
               getTasksCount(dispatch,getUser)//For Update Count
               Alert('success', `Your ${MAINTASK} is Updated Successfully`)
           } else {
               Alert('warning', response.message)
           }
       }
       catch (error) {
           Alert('error', error.message)
       }
   dispatch(isLoaded());
 }