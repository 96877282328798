import {
    isLoaded,
    workingDays,
  } from "../actions";
import API from "../../../Common/Network/API";
import store from "../../../Common/Store";
import Alert from "../../../Common/Alert";

export async function getWorkingDays(dispatch, getUser) {
    try {
      var response = await API.post(
        "active_time.php",
        {
          projectId: getUser.projectId,
        },
        {},
        false
      );
      if (response.status === "True") {
        dispatch(workingDays(response.data));
        store.dispatch(workingDays(response.data));
      } else {
        dispatch(workingDays([]));
        store.dispatch(workingDays([]));
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  }