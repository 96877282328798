import {
    isLoaded,
    workingHours,
  } from "../actions";
import API from "../../../Common/Network/API";
import store from "../../../Common/Store";
import Alert from "../../../Common/Alert";

export async function getWorkingHours(dispatch, getUser) {
    try {
      var response = await API.post(
        "get_user_stories.php",
        {
          projectId: getUser.projectId,
          action: "working_hours",
        },
        {},
        false
      );
      if (response.status === "True") {
        dispatch(workingHours(response.data));
        store.dispatch(workingHours(response.data));
      } else {
        dispatch(workingHours([]));
        store.dispatch(workingHours([]));
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  }